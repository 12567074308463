#main{
    max-width: 950px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 90px auto 20px auto;
    padding-bottom: 60px;
    position: relative;
}
 
.main-img{
    max-width: 350px;
    max-height: 700px;
    width: 100%;
}
.main-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    box-shadow: 5px 5px 60px #7F27A6;
    animation: header 6s infinite ease-in-out  ;
}
@keyframes header{
    0% {
        transform: rotate3d(1, 1, 1, 5deg);
    }
    50%{
        transform: rotate3d(1, 1, 1, -5deg);
    }
    100%{
        transform: rotate3d(1, 1, 1, 5deg);
    }
    
}
.main-text strong{
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.65rem;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
}
.main-text{
    width: 450px;
    position: relative;
}
.main-text::before{
    content: 'LA RACE';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -30px;
    font-size: 7rem;
    color: #296dff28;;
    line-height: 7rem;
    font-weight: 700;
    z-index: -1;
}
.main-text h1{
    color: #ffffff;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 1px;
}
.main-text h1 span{
    color: #B631E1;
}
.main-text p{
    color: #ffffff;
    margin: 30px 0px;
    letter-spacing: 1px;
    font-size:1rem;
}
.main-text a,
.summery-heading a,
.dribble-btn,
.contact-btn,
.about-heading a{
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 6px;
    font-size: 0.8rem;
    border-bottom: 1px solid #689af8;
    padding: 10px 10px 10px 0px;
    transition: all 508ms cubic-bezier(.77, 0, .175, 1);
}
.main-text a:hover,
.summery-heading a:hover,
.dribble-btn:hover,
.btn-c a,
.contact-btn:hover,
.about-heading a:hover{
    padding: 10px;
    background-color: rgb(104, 154, 248,0.1);
    transition: all 508ms cubic-bezier(.77, 0, .175, 1);
}
.main-btns{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.main-btns a{
    width:170px;
    height: 48px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    font-size: 0.9rem;
    letter-spacing: 1px;
    border-radius: 4px;
}
.main-btns .active-btn-header{
    background-color: #B631E1;
    border: none;
}


@media(max-width:1010px){
    #main{
        width: 90%;
    }
}
@media(max-width:850px){
    #main{
        padding:40px 0px 70px 0px;
        min-height: 550px;
    }
    .main-img{
        display: none;
    }
    .main-text{
        max-width: 100%;
        width: 100%;
    }
    .main-text h1{
        font-size: 4rem;
        line-height: 4rem;
    }
}
@media(max-width:460px){
    .main-btns{
        flex-direction: column;
        width: 100%;
    }
    .main-btns a{
        width: 100%;
        margin: 10px 0px;
    }
}
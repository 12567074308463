
#feature-wallet{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#feature-wallet h2{
    margin-bottom: 40px;
    color: #ffffff;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 1px;
}
#feature-wallet h2 span{
    color: #B631E1;
}
.feature-box{
    width:155px;
    height: 160px;
    margin: 0px 20px;
    border-radius: 10px;
    overflow: hidden;
}
.feature-box a img{
    width:100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.item span{
    font-size: 1rem;
    font-weight: 600;
    margin: 5px;
    color: #ffffff;
}
.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#feature-boxes{
    display: flex;
    justify-content: center !important;
    margin: 10px auto 40px auto;
    flex-direction: row;
    flex-wrap: wrap;
}
@media(max-width:600px){
    #feature-wallet{
        margin-top: 35px;
    }
    #feature-wallet h2{
        font-size: 1.6rem;
        text-align: center;
    }

    .feature-box{
        width:120px;
        height: 120px;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');

*{
    font-family: 'Rubik', sans-serif;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
body{
    margin: 0px;
    padding: 0px;
    font-family: 'Rubik', sans-serif;
    background: linear-gradient(90deg, #201D48 -1.1%, #2A033A 107.91%);
}
.how-it-works{
    text-align: center;
    display: flex;
    flex-direction: column;
}
.hiw-heading h2{
    margin-bottom: 40px;
    color: #993EA0;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 1px;
}

.hiw-heading strong{
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.6rem;
    padding-bottom: 8px;
}

.img-hiw{
    width: 100%;
    max-width: 1100px;
    height: auto;
}
/*==Footer=================*/
footer{
    background: linear-gradient(180deg, #7F27A6 51.97%, rgba(127, 39, 166, 0) 99.36%, rgba(127, 39, 166, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 40px 0px;
    margin-top: 80PX;
}
.footer-container{
    width: 100%;
    max-width: 980px;
    margin: auto;
}
.footer-container-flex{
    display: flex;
}
.footer-heading{
    width: 40%;
    display: flex;
    align-items: center;
}
.footer-heading h3{
    font-size: 5rem;
    margin-top: 20px;
    font-weight: 600;
}
.footer-heading h4{
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 1px;
}
.footer-content{
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    width: 70%;
}
.footer-box strong{
    color: #ffffff;
    font-size: 1.5rem;
}
.footer-box ul{
    margin-top: 15px;
}
.footer-box ul li a,
.footer-box ul li{
    color: #ffffff;
    font-size: 0.9rem;
    opacity: 0.9;
    padding: 2px 0px;
    transition: all ease 0.3s;
}
.footer-box ul li{
    display: flex;
    margin: 8px 0px;
    align-items: center;
}
.footer-box ul li:hover,
.footer-box ul li a:hover{
    opacity: 0.6;
    transition: all ease 0.3s;
}
.footer-bottom{
    display: flex;
    align-items: center;
    margin-top: 100px;
    justify-content: space-evenly;
    color: #fff;
}
@media(max-width:1060px){
    .footer-container{
        max-width: 800px;
    }
}
@media(max-width:850px){
    footer{
        padding: 50px 0px;
    }
    .footer-container{
        width: 90%;
    }
    
    .footer-content{
        display: grid;
        grid-template-columns: 2fr;
        grid-gap: 10px;
    }
    .footer-heading{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
@media(max-width:680px){
    .footer-heading h4{
        font-size: 1.6rem;
        line-height: 30px;
    }
    .footer-heading h3{
        font-size: 2.3rem;
    }
    .footer-container-flex{
        flex-direction: column;
    }
    .footer-bottom{
        flex-wrap: wrap;
        justify-content:space-between;
        align-items: center;
        padding: 0px 20px;
    }
}
@media(max-width:340px){
    .footer-content{
        grid-template-columns: 1fr;
        margin-top: 40px;
    }
    .footer-box{
        margin: 0px;
    }
    .footer-box strong{
        font-size: 1.5rem;
    }
}

#nftservice{
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}
.nftservice-box-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width:100%;
}
.nftservice-box{
    width:300px;
    height: 380px;
    box-shadow: 2px 2px 30px rgba(0,0,0,0.1);
    background-color: transparent;
    padding: 40px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5%;
    border: 1px solid #993EA0;
    box-shadow: 5px 5px 20px #8027a65e;
}
.service-img{
    width:70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}
.service-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.service{
    display: flex;
    align-items: center;
}
.name-service{
    display: flex;
    flex-direction: column;
}
.name-service strong{
    color: #ffffff;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
}
.name-service span{
    color: #979797;
    font-size: 0.8rem;
}
.box-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.service-detail p{
    font-size: 0.9rem;
    color: #ffffff;
    letter-spacing: 0.1rem;
}
.nftservice-box:hover{
    transform: translateY(-10px);
    transition: all ease 0.2s;
    box-shadow: 5px 5px 50px #0000005e;
}


@media(max-width:1060px){
    .nftservice-box{
        width:45%;
        padding: 10px;
    }
}
@media(max-width:790px){
    .nftservice-box{
        width:90%;
        height: auto;
        padding: 30px;
    }
    #nftservice{
        padding-top: 0;
    }
}
@media(max-width:340px){
    .box-top{
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
}